import React from "react";
import {Route} from "react-router";
import GoGetter from "./GoGetter";
import StarGetter from "./StarGetter";
import PowerPlayer from "./PowerPlayer";
import Core4 from "./Core4";
import MarketingSystem from "./MarketingSystem";
import TopGun from "./TopGun";
import TopTen from "./TopTen";
import {joinPath} from "../../utils/helpers";
import IFrame from "../../components/IFrame";
import CompensationPlan from "./CompensationPlan";
import Policies from "./Policies";
import Compliance from "./Compliance";
import EventSpeakers from "./EventSpeakers";
import Glossary from "./Glossary";
import MyForms from "./MyForms";
import Convention from "./Convention";
import BookKitTransfer from "./BookKitTransfer";
import PVTransfer from "./PVTransfer";
import TicketManagement from "./TicketManagement";
import {Redirect} from "react-router-dom";
import RoyalPromo from "./RoyalPromo";

function MembersRoutes(parentMatch, loginUID) {

    return [
        <Route key={"sot"}
            path={joinPath(parentMatch.url, "/members/sot")}
            render={() => <IFrame
                url={`https://checkout.lifeinfoapp.com/quickcheckout.aspx?type=subnew&id=754&login=${loginUID}`}/>
            }
        />,
        <Route key={"go-getter"}
            path={joinPath(parentMatch.url, "/members/go-getter")}
            render={() => <GoGetter/>
            }
        />,
        <Route key={"compensation-plan"}
            path={joinPath(parentMatch.url, "/members/compensation-plan")}
            render={() => <CompensationPlan/>
            }
        />,
        <Route key={"policies"}
            path={joinPath(parentMatch.url, "/userprofile/profile/policies")}
            render={() => <Policies/>
            }
        />,
        <Route key={"tar-getter"}
            path={joinPath(parentMatch.url, "/members/star-getter")}
            render={() => <StarGetter/>
            }
        />,
        <Route key={"power_player"}
            path={joinPath(parentMatch.url, "/members/power_player")}
            render={() => <PowerPlayer/>
            }
        />,
        <Route key={"top-gun"}
            path={joinPath(parentMatch.url, "/members/programs and explanations/power player")}
            render={() => <PowerPlayer/>
            }
        />,
        <Route key={"power_player"}
            path={joinPath(parentMatch.url, "/members/core4")}
            render={() => <Core4 parentMatch={parentMatch}/>
            }
        />,
        <Route key={"compliance-test"}
            path={joinPath(parentMatch.url, "/members/programandexplanation/core 4")}
            render={() => <Core4 parentMatch={parentMatch}/>
            }
        />,
        <Route key={"marketing_systems"}
            path={joinPath(parentMatch.url, "/members/marketing_systems")}
            render={() => <MarketingSystem/>
            }
        />,
        <Route key={"royal-starfish"}
            path={joinPath(parentMatch.url, "/members/royal-starfish")}
            render={() => <RoyalPromo/>
            }
        />,
        <Route key={"top-gun"}
            path={joinPath(parentMatch.url, "/members/top-gun")}
            render={() => <TopGun/>
            }
        />,
        <Route key={"monthlystandings"}
            path={joinPath(parentMatch.url, "/members/monthlystandings")}
            render={() => <TopTen/>
            }
        />,
        <Route key={"compliance-test"}
            path={joinPath(parentMatch.url, "/members/compliance/test")}
            render={() => <IFrame url={"https://legacy.lifeinfoapp.com/ComplianceTest.aspx?ssl=1"}/>
            }
        />,
        <Route key={"compliance"}
            path={joinPath(parentMatch.url, "/members/compliance")}
            render={() => <Compliance/>
            }
        />,
        <Route key={"compensation_plan"}
            path={joinPath(parentMatch.url, "/members/compensation_plan")}
            render={() => <CompensationPlan/>
            }
        />,
        <Route key={"events-speakers"}
            path={joinPath(parentMatch.url, "/members/events-speakers")}
            render={() => <EventSpeakers/>
            }
        />,
        <Route key={"glossary"}
            path={joinPath(parentMatch.url, "/members/glossary")}
            render={() => <Glossary/>
            }
        />,
        <Route key={"my forms"}
            path={joinPath(parentMatch.url, "/members/full office/my forms")}
            render={() => <MyForms/>}
        />,
        <Route key={"leadership-convention"}
            path={joinPath(parentMatch.url, "/members/events/leadership convention")}
            render={() => <Convention/>}
        />,
        <Route key={"pv-transfer"}
            path={joinPath(parentMatch.url, "/members/full office/pv transfer")}
            render={() => <Redirect to={joinPath(parentMatch.url, `/members/full office/product-transfer`)}/> }
        />,
        <Route key={"pv-transfer"}
            path={joinPath(parentMatch.url, "/members/full office/product transfer")}
            render={() => <Redirect to={joinPath(parentMatch.url, `/members/full office/product-transfer`)}/> }
        />,
        <Route key={"product-transfer"}
            path={joinPath(parentMatch.url, "/members/full office/product-transfer")}
            render={() => <PVTransfer/>}
        />,
        <Route key={"book-kit-transfer"}
            path={joinPath(parentMatch.url, "/members/full office/bookKitTransfer")}
            render={() => <BookKitTransfer/>}
        />,
        <Route key={"book-kit-transfer2"}
            path={joinPath(parentMatch.url, "/members/bookKitTransfer")}
            render={() => <BookKitTransfer/>}
        />,
        <Route key={"ticket-management"}
            path={joinPath(parentMatch.url, "/members/events/ticket_management")}
            render={() => <TicketManagement/>}
        />,
        <Route key={"ticket-management2"}
            path={joinPath(parentMatch.url, "/members/events/ticket management")}
            render={() => <Redirect to={joinPath(parentMatch.url, `/members/events/ticket_management`)}/> }
        />,
    ]

}

export default MembersRoutes;