import {makeAutoObservable, reaction} from "mobx";
import services from "../services";
import {extractErrorMessage} from "../utils/helpers";
import {generateCreteEntity, generateLoadEntity, generateLoadList} from "../utils/mobx";

class TicketStore {
    ticketLoading = null;
    ticketInfo = null;
    ticketError = null;

    currentPeriodInfo = null;
    currentPeriodLoading = null;

    listPeriodsInfo = null;
    listPeriodsLoading = null;
    PVPeriodID = null;
    forUser = null

    userTickets = []
    userTicketsTransfers = []
    userTicketsLoading = null;

    userTicketsNext = []
    userTicketsTransfersNext = []
    userTicketsNextLoading = null;


    conventionInfo = null
    conventionTickets = []
    conventionTicketsTransfers = []
    conventionTicketsLoading = null;


    transferringTicket= null;
    transferTicketError= null;

    periodsLoading = null;
    periods = []

    constructor(_, authStore) {
        makeAutoObservable(this);
        this.authStore = authStore;
        reaction(() => this.periods.slice(),
            periods => {
                if (periods.length > 1) {
                    let beforeTimeSwitch = false

                    const today = new Date()
                    if (today.getDate() === 1 && today.getHours() < 2) {
                        beforeTimeSwitch = true
                    }
                    if (today.getDate() < 3 && !beforeTimeSwitch)
                        this.setPVPeriodID(periods[1].PVPeriodID)
                    else
                        this.setPVPeriodID(periods[0].PVPeriodID)
                } else if (periods.length == 1) {
                    this.setPVPeriodID(periods[0].PVPeriodID)
                }
            })


        reaction(
            () => this.forUser,
            forUser => {
                if (forUser) {
                    void this.refreshTickets();
                }
            }
        );


        reaction(
            () => this.PVPeriodID,
            PVPeriodID => {
                if (PVPeriodID) {
                    void this.refresh();
                    void this.refreshTickets();
                }
            }
        );

    }

    setError(error, type = "ticketError") {
        error = extractErrorMessage(error);
       switch (type) {
            case "transferTicket":
                this.transferTicketError = error;
                break;
           default:
               this.error = error;
       }



    }

    setPVPeriodID(value) {
        this.PVPeriodID = value
    }

    setUserFor(value) {
        this.forUser = value
    }

    async refresh() {
        await this.dashboardTickets({
            userID: this.forUser,
            pVPeriodID: this.PVPeriodID,
        });
    }


    async refreshTickets() {
        await this.loadUserTickets({
            userID: this.forUser,
            pVPeriodID: this.PVPeriodID,
        });
        await this.loadUserTicketsNext({
            userID: this.forUser,
            pVPeriodID: this.PVPeriodID + 1,
        });

        await this.loadConventionTickets({
            userID: this.forUser,
            pVPeriodID: this.PVPeriodID,
        });
    }


    get currentPeriod() {
        return this.periods.find(x => x.PVPeriodID === this.PVPeriodID);
    }


    listPeriods = generateLoadEntity(
        "listPeriodsInfo",
        this,
        "listPeriodsLoading",
        () => {
            return services.MyTeams.listPeriods(22);
        },
        "listPeriodsInfo"
    );

    dashboardTickets = generateLoadEntity(
        "ticketInfo",
        this,
        "ticketLoading",
        (params) => {
            return services.TicketService.dashboardTickets(params);
        },
        "ticketInfo"
    );

    loadUserTickets = generateLoadList(
        "userTickets",
        this,
        "userTicketsLoading",
        async (params) => {
            const result = await services.TicketService.pvStartEndDate(params);
            this.userTickets.replace(result.tickets)
            this.userTicketsTransfers.replace(result.transfers)
        }
    );


    loadConventionTickets = generateLoadList(
        "conventionTickets",
        this,
        "conventionTicketsLoading",
        async (params) => {
            const result = await services.TicketService.conventionTickets(params);
            this.conventionTickets.replace(result.tickets)
            this.conventionTicketsTransfers.replace(result.transfers)
            this.conventionInfo = result.info
        }
    );

    loadUserTicketsNext = generateLoadList(
        "userTicketsNext",
        this,
        "userTicketsNextLoading",
        async (params) => {
            const result = await services.TicketService.pvStartEndDate(params);
            this.userTicketsNext.replace(result.tickets)
            this.userTicketsTransfersNext.replace(result.transfers)
        }
    );


    transferTicket = generateCreteEntity(
        "transferTicket",
        this,
        "transferringTicket",
        async (params) => {
            const result = await services.TicketService.transferTicket(params);
            await this.refreshTickets();
        }
    );

    get ticketForName (){

        if (this.forUser === this.authStore.currentUser.userID) {
            return null
        }
        else if( !this.ticketInfo){
            return null
        }
        else {
            const user = this.ticketInfo.find(x => x.UserID === this.forUser)
            if ( user )
                return ` ${user.LastName}`
            else
                return null
        }

    }

    periodsLoading = null;
    periods = []



    loadPeriods = generateLoadList(
        "periods",
        this,
        "periodsLoading",
        (userID) => {
            return services.TicketService.listPeriods(userID);
        },
        "periods"
    );


}

export default TicketStore;
