import CommonStore from "./commonStore";
import AudioStore from "./audioStore";
import UserShippingInfoStore from "./userShippingInfoStore";
import UserBillingInfoStore from "./userBillingInfoStore";
import AuthStore from "./authStore";
import StatStore from "./statStore";
import LibraryStore from "./libraryStore";
import SeriesStore from "./seriesStore";
import SalesAchieverAudiosStore from "./salesAchieverAudiosStore";
import AllTimesGreatStore from "./allTimesGreatStore";
import RascalTunesSeries from "./rascalTunesStore";
import SpeakerStore from "./speakerStore";
import MenuStore from "./menuStore";
import NewsStore from "./newsStore";
import PacksStore from "./packsStore";
import VideoStore from "./videoStore";
import MediaHandlers from "./MediaHandlers";
import UserProfileStore from "./userProfileStore";
import SpouseProfileStore from "./spouseProfileStore";
import BookStore from "./bookStore";
import SendOutStore from "./sendOutStore";
import EnrollmentStore from "./enrollmentStore";
import MemberEnrollmentStore from "./memberEnrollmentStore";
import ShopStore from "./shopStore";
import ShareStore from "./shareStore";
import OnboardStore from "./onboardStore";
import ConventionProgramStore from "./conventionProgram";
import ShoppingBossStore from "./shoppingBossStore";
import HelpStore from "./helpStore";
import SupportStore from "./supportStore";
import SubscriptionStore from "./subscriptionStore";
import SeminarWebinarStore from "./seminarWebinarStore";
import TransactionsStore from "./transactionsStore";
import LifelineStore from "./lifelineStore";
import TopGunStore from "./topGunStore";
import TopTenStore from "./topTenStore";
import MyFormsStore from "./myFormsStore";
import MyTeamsStore from "./myTeamsStore";
import BannerStore from "./bannerStore";
import IdentityVerificationStore from "./identityVerificationStore";
import GiftCardsStore from "./giftCardsStore";
import ConventionLeadershipStore from "./conventionLeadershipStore";
import CustomersStore from "./customersStore";
import CriticalMessageStore from "./criticalMessageStore";
import DashboardStore from "./DashboardStore";
import PeriodsStore from "./periodsStore";
import MyVolumeStore from "./myVolumeStore";
import ResetPasswordStore from "./resetPasswordStore";
import MyProgressStore from "./myProgressStore";
import IncomeAllocationStore from "./incomeAllocationStore";
import RecoveryStore from "./recoveryStore";
import ServiceStatusStore from "./serviceStatusStore";
import WebTextStore from "./webTextStore";
import UserSearchStore from "./userSearchStore";
import ProductSearchStore from "./productStore";
import PVTransferStore from "./pvTransferStore";
import MyIncomeStore from "./myIncomeStore";
import TestStore from "./testStore";
import MyTrainingIncomeStore from "./myTrainingIncomeStore";
import EventsScheduleStore from "./EventsScheduleStore";
import KitOnlyStore from "./kitOnlyStore";
import TicketStore from "./ticketStore";


const libraryStore = new LibraryStore();
const periodsStore = new PeriodsStore();
const audioStore = new AudioStore(libraryStore);
const videoStore = new VideoStore(libraryStore);
const bookStore = new BookStore(libraryStore);
const mediaHandlers = new MediaHandlers(libraryStore, audioStore, videoStore, bookStore);

const commonStore = new CommonStore();
const statStore = new StatStore();
const packsStore = new PacksStore(libraryStore);
const menuStore = new MenuStore(libraryStore, packsStore, commonStore);
const newsStore = new NewsStore();
const seriesStore = new SeriesStore(libraryStore);

const salesAchieverAudiosStore = new SalesAchieverAudiosStore(libraryStore);
const allTimesGreatStore = new AllTimesGreatStore(libraryStore);
const rascalTunesStore = new RascalTunesSeries(libraryStore);
const speakerStore = new SpeakerStore(libraryStore);
const authStore = new AuthStore(commonStore, menuStore, statStore);
const userProfileStore = new UserProfileStore(commonStore, authStore);
audioStore.setAuthStore( authStore )
const spouseProfileStore = new SpouseProfileStore(commonStore);
const userShippingInfoStore = new UserShippingInfoStore(commonStore);
const userBillingInfoStore = new UserBillingInfoStore(authStore,commonStore);
const sendOutStore = new SendOutStore();
const enrollmentStore = new EnrollmentStore(commonStore);
const onboardStore = new OnboardStore(commonStore, authStore);
const shopStore = new ShopStore(commonStore, authStore);
const shareStore = new ShareStore();
const supportStore = new SupportStore();
const memberEnrollmentStore = new MemberEnrollmentStore(userBillingInfoStore, authStore, commonStore, supportStore);
const helpStore = new HelpStore();
const conventionProgramStore = new ConventionProgramStore();
const shoppingBossStore = new ShoppingBossStore(authStore);
const subscriptionStore = new SubscriptionStore();
const seminarWebinarStore = new SeminarWebinarStore();
const transactionsStore = new TransactionsStore();
const identityVerificationStore = new IdentityVerificationStore();
const lifelineStore = new LifelineStore();
const topGunStore = new TopGunStore();
const topTenStore = new TopTenStore();
const myFormsStore = new MyFormsStore();
const bannerStore = new BannerStore(authStore);
const myTeamsStore = new MyTeamsStore(periodsStore);
const giftCardsStore = new GiftCardsStore(commonStore);
const conventionLeadershipStore = new ConventionLeadershipStore();
const customersStore = new CustomersStore(authStore);
const criticalMessageStore = new CriticalMessageStore();
const dashboardStore = new DashboardStore(periodsStore);
const myVolumeStore = new MyVolumeStore(periodsStore, authStore);
const resetPasswordStore = new ResetPasswordStore();
const myProgressStore = new MyProgressStore(periodsStore, authStore);
const myIncomeStore = new MyIncomeStore(periodsStore, authStore);
const myTrainingIncomeStore = new MyTrainingIncomeStore(periodsStore, authStore);
const incomeAllocationStore = new IncomeAllocationStore(periodsStore, authStore);
const recoveryStore = new RecoveryStore();
const serviceStatusStore = new ServiceStatusStore();
const testStore = new TestStore();
const webTextStore = new WebTextStore();
const userSearchStore = new UserSearchStore();
const productSearchStore = new ProductSearchStore();
const pvTransferStore = new PVTransferStore();
const eventsScheduleStore = new EventsScheduleStore();
const kitOnlyStore = new KitOnlyStore(userBillingInfoStore, authStore, commonStore);
const ticketStore = new TicketStore(periodsStore, authStore);


const stores = {
    authStore,
    audioStore,
    bookStore,
    commonStore,
    customersStore,
    libraryStore,
    menuStore,
    mediaHandlers,
    newsStore,
    packsStore,
    seriesStore,
    salesAchieverAudiosStore,
    allTimesGreatStore,
    rascalTunesStore,
    speakerStore,
    statStore,
    videoStore,
    userProfileStore,
    userShippingInfoStore,
    userBillingInfoStore,
    spouseProfileStore,
    sendOutStore,
    enrollmentStore,
    memberEnrollmentStore,
    shopStore,
    shareStore,
    onboardStore,
    helpStore,
    supportStore,
    conventionProgramStore,
    shoppingBossStore,
    subscriptionStore,
    seminarWebinarStore,
    transactionsStore,
    lifelineStore,
    identityVerificationStore,
    topGunStore,
    topTenStore,
    myFormsStore,
    myTeamsStore,
    bannerStore,
    giftCardsStore,
    conventionLeadershipStore,
    criticalMessageStore,
    dashboardStore,
    periodsStore,
    resetPasswordStore,
    myVolumeStore,
    myProgressStore,
    incomeAllocationStore,
    serviceStatusStore,
    webTextStore,
    userSearchStore,
    productSearchStore,
    pvTransferStore,
    myIncomeStore,
    recoveryStore,
    testStore,
    myTrainingIncomeStore,
    recoveryStore,
    kitOnlyStore,
    ticketStore,
    eventsScheduleStore,

};

export default stores;