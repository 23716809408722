import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from "react-router-dom";
import { AppBar, Avatar, Badge, Container, Icon, IconButton, ListItem, ListItemAvatar, ListItemText, makeStyles, Menu, MenuItem, Toolbar } from '@material-ui/core';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import NotificationsIcon from '@material-ui/icons/Notifications';
import ShareIcon from '@material-ui/icons/Share';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import AudioPlayer from './AudioPlayer';
import { useStores } from '../hooks/use-stores';
import ShopIcon from "@material-ui/icons/ShoppingCart";
import {joinPath} from "../utils/helpers";
import {common} from "@material-ui/core/colors";
import { shopTypes } from "../containers/shop/shopContext"

const useStyles = makeStyles((theme) => ({
    appBarRoot: {
        position: "fixed",
        height: 58,
        backgroundColor: "black",
        borderBottom: "1px solid #007BFF"
    },
    appBarContainer: {
        height: 58
    },
    appbarToolbar: {
        height: "100%"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    appLogo: {
        paddingLeft: theme.spacing(1),
        whiteSpace: "nowrap",
        cursor: "pointer"
    },
    hideAppLogoMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    appLogoImg:({isRoyal})=>({
        height: isRoyal?35:27,
        width: 150,
    }),
    appLogoText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "35px",
    },
    grow: {
        flexGrow: 1,
    },
    appbarIcons: {
        whiteSpace: "nowrap"
    },
    whiteIcon: {
        color: "white"
    },
    smallAvatar: {
        width: 30,
        height: 30
    },
}));

const StarfishHeader = ({ currentUser, isPhone, isRoyal, handleLogout, prefix }) => {
    const { t } = useTranslation();
    const { authStore,  commonStore, shopStore } = useStores();
    const classes = useStyles({ isRoyal} );
    const history = useHistory();

    const location = useLocation();
    const isAdminView = location.search.includes('isAdminView')
    const isAutoShip = location.pathname.includes('autoship')

    const shopOptions = isAutoShip?  shopTypes.autoship : shopTypes.standard
    const {cartItemsProp} = shopOptions
    const  [cartItems, setCartItems] = React.useState(null)
    const [userMenuanchorEl, setUserMenuAnchorEl] = React.useState(null);
    const isUserMenuOpen = Boolean(userMenuanchorEl);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { notificationsCounter } = commonStore;

    const { userCartGuid
    } = shopStore;


    useEffect(async () => {
        getCartData();
    }, [currentUser, isAutoShip]);

    const getCartData = async () => {
        setCartItems( null )
        switch (shopOptions.shopId) {
            case "subscriptions":
                await shopStore.loadSubscriptionsCartItems();
                break;
            case "autoship":
                await shopStore.loadAutoShipCartItems();
                break;
            case "standard":
                if (currentUser){
                    await authStore.loadShopUserDetails();
                    const customerLevelID = currentUser.shoppingUserDetails?.CustomerLevelID;
                    await shopStore.getUserCart({cartGuid: userCartGuid, customerLevelID});
                }

                break;

        }
        setCartItems( shopStore[cartItemsProp])
    };



    const handleUserMenuOpen = (event) => {
        history.push({
            pathname:  isRoyal?'/royalstarfish/userprofile':'/starfish/userprofile',
            state: { fromModule: 'STARFISH' }
        });
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };



    const handleBellMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleBellMenuClose = () => {
        setAnchorEl(null);
    };

    const showConventionAnn = () => {
        handleBellMenuClose();

        history.push(joinPath( prefix, "conventionnews"));
    };

    const showLifeLineAnn = () => {
        handleBellMenuClose();

        history.push(joinPath( prefix, "lifeline"));
    };

    const showNews = () => {
        handleBellMenuClose();
        history.push(joinPath( prefix, "news"));
    };

    const menuId = 'notifications-menu';
    const isMenuOpen = Boolean(anchorEl);


    const goToHome = () => {
        if (!isAdminView) {
            history.push({
                pathname:  isRoyal?'/royalstarfish':'/starfish',
                state: { fromModule: 'STARFISH' }
            });
        }
    };

    const goToShare = () => {

       const advancedShareForStarfish = !isRoyal && authStore.currentUser.isMember
           && ( authStore.subscriptions.hasLTBasic || authStore.subscriptions.hasLTPremium )

        history.push({
            pathname:  isRoyal?'/royalstarfish/share': ( !advancedShareForStarfish?   "/starfish/share/shareenrollment" :'/starfish/share'),
            state: { fromModule: 'STARFISH' }
        });
    };

    const goToNews = () => {
        history.push({
            pathname: isRoyal?'/royalstarfish/news':'/starfish/news',
            state: { fromModule: 'STARFISH' }
        });
    };


    return (<>
        <AppBar color={"secondary"} position="static" classes={{ root: classes.appBarRoot }}>
            <Container maxWidth={"md"} className={classes.appBarContainer}>
                <Toolbar disableGutters variant="dense" className={classes.appbarToolbar}>
                    <span className={classes.appLogo}>
                        <img src={
                            isRoyal
                                ? `/img/RoyalHeader.png`
                                : `/starfish_full.png`} className={classes.appLogoImg} onClick={goToHome} />
                    </span>
                    <div className={`${classes.grow}`} >
                    </div>
                    <AudioPlayer isPhone={isPhone} isStarfish={commonStore.onStarfishOrRoyalStarfish} />
                    {!isAdminView && <div className={classes.appbarIcons} >
                        {( !isAutoShip ) ? <Link to={ joinPath(isRoyal?'/royalstarfish':'/starfish', "/shop/cart")}><Badge overlap={"rectangular"} badgeContent={cartItems} color="secondary">
                            <ShopIcon className={classes.whiteIcon} />
                        </Badge></Link>: null }
                        {( isAutoShip ) ? <Link to={ joinPath(isRoyal?'/royalstarfish':'/starfish', "/royal/marketplaceproducts/autoship/cart")}><Badge overlap={"rectangular"} badgeContent={cartItems} color="secondary">
                            <ShopIcon className={classes.whiteIcon} />
                        </Badge></Link>: null }
                        <IconButton color="inherit" onClick={goToShare}>
                            <ShareIcon className={classes.whiteIcon} />
                        </IconButton>
                        { (authStore.subscriptions.hasLTBasic || authStore.subscriptions.hasLTPremium || authStore.currentUser.isMember)
                            ?   <IconButton aria-label={`show ${notificationsCounter} new notifications`} color="inherit"
                                               onClick={handleBellMenuOpen}>
                                    <Badge overlap={"rectangular"} badgeContent={notificationsCounter} color="secondary">
                                       <NotificationsIcon/>
                                    </Badge>
                                </IconButton>
                            :   <IconButton color="inherit" onClick={goToNews}>
                                    <NotificationsIcon />
                                </IconButton>}
                        <IconButton color="inherit" onClick={handleUserMenuOpen}>
                            <AccountCircleRoundedIcon className={classes.smallAvatar} />
                        </IconButton>
                    </div>}
                </Toolbar>
            </Container>
        </AppBar>

        <Menu
            anchorEl={anchorEl}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleBellMenuClose}
        >
            {(authStore.subscriptions.hasLTBasic || authStore.subscriptions.hasLTPremium )
                && <MenuItem onClick={showLifeLineAnn}><div className={"notif-icon notif-success"}>
                <Avatar src="https://resources.lifeinfoapp.com/public/lifeappicons/listen_to_life_line.jpg" >LL</Avatar></div>Life Line</MenuItem> }
            <MenuItem onClick={showNews}><div className={"notif-icon notif-success"}>
                <Icon style={{ color: common.white }} className="fa fa-newspaper" /></div>News & Announcements</MenuItem>
            {authStore.currentUser.isMember && <MenuItem onClick={showConventionAnn}><div className={"notif-icon notif-danger"}>
                <Icon style={{ color: common.white }} className="fa fa-bullhorn" /></div>Convention Announcements</MenuItem>}
        </Menu>

        <Menu
            anchorEl={userMenuanchorEl}
            id={'starfishMenu'}
            keepMounted
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={isUserMenuOpen}
            onClose={handleUserMenuClose}
        >
            <div className={classes.userInfo}>
                <ListItem>
                    <ListItemAvatar><span>{currentUser.firstName.slice(0,1)}</span></ListItemAvatar>
                    <ListItemText primary={`${currentUser.firstName} ${currentUser.lastName}`} secondary={<>{currentUser.username}  <br /> {currentUser.userID} </>}></ListItemText>
                </ListItem>
            </div>
            <ListItem button onClick={handleLogout}>
                <ListItemIcon className={classes.icon}><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary={t("Logout")} />
            </ListItem>
        </Menu>
    </>);
};

export default StarfishHeader;
