import RestService from "./RestService";

export default class TicketService extends RestService {
  constructor() {
    super("/api/ticket");
  }

  async dashboardTickets(params) {
    return this.get(`${this.baseUrl}/dashboard-tickets`, params);
  }

  async getCurrentPVPeriod() {
    return this.get(`${this.baseUrl}/current-pv-period`);
  }

  listPeriods(userID){
    return this.get(`${this.baseUrl}/periods/${userID}`);
  }
  
  async pvStartEndDate(params) {
    return this.get(`${this.baseUrl}/userTickets`, params);
  }

  async conventionTickets(params) {
    return this.get(`${this.baseUrl}/convention-tickets`, params);
  }

  async transferTicket(params) {
    return this.post(`${this.baseUrl}/transfer-ticket`, params);
  }
}
